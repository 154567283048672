export const result = {
  rawdata: {
    de: "Rohdaten",
    es: "Pokemon GO datos",
    en: "Raw Data",
    ja: "レベル情報を見る",
    fr: "Données brutes",
    zh: "原始数据",
  },
  main: {
    de: '<p> Eine Ebene <span class="bold">{{currentLevel}}</span>, mit <span class="bold">{{currentXp}}</span> XP , verdienen <span class="bold">{{xpEarned}}</span> XP pro 5 Minuten würde dies pro Ebene lange dauern. </p>',
    es: '<p>Un nivel <span class="bold">{{currentLevel}}</span>, con <span class="bold">{{currentXp}}</span> XP , ganando <span class="bold">{{xpEarned}}</span> XP por 5 minutos tomaría tanto tiempo por nivel.</p>',
    en: '<p>A level <span class="bold">{{currentLevel}}</span>, with <span class="bold"> {{currentXp}} </span> XP, earning <span class="bold">{{xpEarned}}</span> XP per 5 minutes would take this long per level.</p>',
    ja: '<p>まず現在は<span class="bold">{{currentLevel}}</span>レベルで, <span class="bold">{{currentXp}}</span> XPとして考えます。 毎5分ごとに<span class="bold">{{xpEarned}}</span>XPをもらっているのなら次のレベルに進む為にはこれぐらいの時間かかります.</p>',
    fr: '<p>Un niveau <span class="bold"> {{currentLevel}} </span>, avec <span class="bold">{{currentXp}}</span> XP, gagnant <span class="bold">{{xpEarned}}</span> XP par 5 minutes prendrait ce long par niveau. </p>',
    zh: '<p><span class="bold">{{currentLevel}}</span>级, <span class="bold">{{currentXp}}</span> XP, 每5分钟获得<span class="bold">{{xpEarned}}</span>的话, 需要升级的时间是</p>',
  },
  lvl: {
    de: "lvl",
    es: "lvl",
    en: "lvl",
    ja: "lvl",
    fr: "lvl",
    zh: "等级",
  },
  timeToReachLevel: {
    de: "Zeitebene zu erreichen",
    es: "Tiempo para alcanzar el nivel",
    en: "Time to reach level",
    ja: "かかる時間",
    fr: "Temps pour atteindre le niveau",
    zh: "升级需要的时间",
  },
  hour: {
    de: "stunde",
    es: "hora",
    en: "hour",
    ja: "時間",
    fr: "heure",
    zh: "小时",
  },
  hour_plural: {
    de: "stunden",
    es: "horas",
    en: "hours",
    ja: "時間",
    fr: "heures",
    zh: "小时",
  },
  minute: {
    de: "minute",
    es: "minuto",
    en: "minute",
    ja: "分",
    fr: "minute",
    zh: "分",
  },
  minute_plural: {
    de: "minuten",
    es: "minutos",
    en: "minutes",
    ja: "分",
    fr: "minutes",
    zh: "分",
  },
  twitterMessage: {
    de: "Prüfen Sie, wie lange es dauert für mich in Pokémon GO um Stufe nach oben !",
    es: "Confirmar el tiempo que va a tomar para mí para subir de nivel en Pokémon GO !",
    en: "Check out how long it'll take for me to level up in Pokémon GO!",
    ja: "しゅげー、見てみな Pokémon GO で次のレベルに進むはこらぐらいの時間かかるそう！",
    fr: "Découvrez combien de temps ça va prendre pour moi de monter de niveau dans Pokémon GO !",
    zh: "Check out how long it'll take for me to level up in Pokémon GO!",
  },
}